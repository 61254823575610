import { tossColor } from "@/containers/Toss/constants/color";
import { sessionStorage } from "@/utils/storage";
import { Box, Table, Tbody, Td, Tr } from "@chakra-ui/react";
import { useOverlay } from "@toss/use-overlay";
import {
  VStack,
  HStack,
  OverlayModal,
  ColorIcon,
  colors,
  Button,
} from "goi_common";
import React from "react";

export default function useNoticeModal({
  isToss = false,
}: {
  isToss: boolean;
}) {
  const overlay = useOverlay();
  const noticeModal = sessionStorage.get("noticeModal");

  const openModal = () => {
    if (noticeModal === "true") {
      return;
    }

    overlay.open(({ isOpen: isOpen, close: onClose }) => {
      return (
        <OverlayModal
          isOpen={isOpen}
          modalContentStyle={{
            minWidth: "360px",
            maxWidth: "360px",
            margin: "0 20px",
            minHeight: "none",
            borderRadius: "10px",
          }}
          overlayModalContent={
            <VStack padding="20px 28px" gap={20} width="100%">
              <HStack justifyContent="flex-end">
                <Box onClick={onClose}>
                  <ColorIcon
                    name="icon-x-mono"
                    color={colors.gray500}
                    size={24}
                  />
                </Box>
              </HStack>
              <Box
                background={isToss ? tossColor["700"] : colors.orange650}
                className="white subtitle_18_sb"
                padding="10px"
                borderRadius="8px"
                textAlign="center"
              >
                2025년 비용 인상 예정 안내
              </Box>
              <Box className="gray900 body_16_m">
                고이 프라임 100원 상조에 관심을 가져주시는 고객 여러분들께
                진심으로 감사드립니다.
                <br />
                <br />
                고이장례연구소는 고객님들에게 최상의 장례 서비스를 제공하기 위해{" "}
                <b
                  css={{ color: isToss ? tossColor["700"] : colors.orange650 }}
                >
                  2025년 2월 4일
                </b>
                부터 상조 상품 총 비용을 인상할 예정입니다.
                <br />
                <br />
                기존 고이 프라임 100원 상조 가입자분들께는 이번 인상이 적용되지
                않으며, 2월 4일 이후 가입하시는 고객님들부터 새로운 가격이
                적용될 예정이오니 이 점 참고하시어 가입해 주시길 부탁드립니다.
                <br />
                <br />
                앞으로도 고객님들께 최상의 장례 서비스를 제공하기 위해
                노력하겠습니다.
                <br />
                <br />
                감사합니다.
              </Box>

              <Table
                className="caption_12_r"
                css={{
                  wordBreak: "keep-all",
                  ["th"]: {
                    fontSize: 12,
                    fontWeight: 600,
                    color: colors.gray500,
                    borderBottom: `1px solid ${colors.gray200}`,
                  },
                  ["td"]: {
                    border: `1px solid ${colors.gray200}`,
                  },
                  ["th, td"]: {
                    textAlign: "center",
                    verticalAlign: "middle",
                    padding: "7px 0",
                  },
                }}
              >
                <Tbody textAlign="center">
                  <Tr
                    background={isToss ? tossColor["700"] : colors.orange650}
                    className="white"
                    fontWeight={600}
                  >
                    <Td></Td>
                    <Td width="42%">변경 전</Td>
                    <Td width="42%">변경 후(2월 4일 이후)</Td>
                  </Tr>
                  <Tr>
                    <Td>가족장</Td>
                    <Td>259만원</Td>
                    <Td>279만원</Td>
                  </Tr>

                  <Tr>
                    <Td>일반장</Td>
                    <Td>359만원</Td>
                    <Td>379만원</Td>
                  </Tr>
                </Tbody>
              </Table>

              <Button
                className="white subtitle_14_sb"
                css={{
                  background: isToss ? tossColor["700"] : colors.orange650,
                  padding: "10px 20px",
                  borderRadius: "8px",
                  margin: "20px 0",
                }}
                onClick={onClose}
              >
                닫기
              </Button>
            </VStack>
          }
          onClose={() => {
            onClose();
          }}
        />
      );
    });

    sessionStorage.set("noticeModal", "true");
  };
  return { openModal };
}
