import { isViewportTopState } from "@/atoms/viewportTopState";
import { HEADER_HEIGHT } from "@/constants/components";
import { PRIME } from "@/constants/productKey";
import { useActivePathCheck } from "@/hooks/useActivePathCheck";
import { sessionStorage, storageKeys } from "@/utils/storage";
import { Box, Stack } from "@chakra-ui/react";

import {
  colors,
  HStack,
  GoiLogo,
  Spacing,
  GLOBAL_MAX_WIDTH,
  NextLink,
  customEvent,
  useResponsive,
  DirectEventTriggerAndTargetType,
  TabBar,
  responsive,
  Button,
  VStack,
} from "goi_common";
import { useRouter } from "next/router";
import { useMemo } from "react";
import { useRecoilValue } from "recoil";

export default function Header({ onOpen }: { onOpen: () => void }) {
  const { isBiggerThanLg } = useResponsive();
  const router = useRouter();

  const isHeaderTransparentPath =
    router.route === "/introduce" || router.route.includes("refund/result");

  const productKey = sessionStorage.get(storageKeys.ProductKey);

  const isNarrativeProcess =
    router.route.includes("must2024") || productKey === PRIME[259].S2MUST;

  const isHeaderContentHiddenRoute =
    router.route.includes("registration") || isNarrativeProcess;

  const isViewportTop = useRecoilValue(isViewportTopState);

  const isHeaderTransparents = useMemo(() => {
    return isHeaderTransparentPath && isViewportTop;
  }, [isHeaderTransparentPath, isViewportTop]);

  return (
    <>
      <VStack
        css={responsive({
          height: {
            base: isHeaderContentHiddenRoute ? 66 : 102,
            lg: HEADER_HEIGHT,
          },
          borderBottom: `1px solid ${
            isHeaderTransparents ? colors.gray300 : colors.gray100
          }`,
          alignItems: "center",
          justifyContent: "center",
          margin: "0 auto",
          position: "fixed",
          width: "100%",
          top: "0",
          backgroundColor: isHeaderTransparents ? "transparent" : colors.white,
          transition: "background-color 0.1s linear",
          zIndex: "1000",
          userSelect: "none",
        })}
      >
        <Stack
          css={responsive({
            flex: 1,
            width: { base: "100%", lg: `${GLOBAL_MAX_WIDTH}px` },
            flexDirection: { base: "column", lg: "row" },
            justifyContent: { base: "space-between", lg: "" },
            alignItems: { base: "", lg: "center" },
            padding: { base: "16px 20px 0", lg: "0px 20px" },
          })}
        >
          <HStack
            alignItems="center"
            justifyContent="space-between"
            gap={isBiggerThanLg ? "36px" : "20px"}
          >
            <NextLink
              id="goi-logo"
              href={isNarrativeProcess ? "/main/must2024/" : "/"}
              css={{ width: "fit-content" }}
              onClick={() => {
                customEvent({
                  newGtm: {
                    path: "gnb",
                    trigger_and_target: "logo-uv_home",
                  },
                });
              }}
            >
              <HStack gap={isBiggerThanLg ? "12px" : "8px"} alignItems="center">
                <GoiLogo
                  color={isHeaderTransparents ? "white" : "black"}
                  size={isBiggerThanLg ? 30 : 24}
                />
                <div
                  className={
                    isHeaderTransparents
                      ? "subtitle_18_b md_subtitle_21_b white"
                      : "subtitle_18_b md_subtitle_21_b black"
                  }
                >
                  PRIME
                </div>
              </HStack>
            </NextLink>
            {!isHeaderContentHiddenRoute && (
              <Button
                className="subtitle_14_b lg_over_hidden"
                css={{
                  width: "95px",
                  padding: "6px 12px",
                  backgroundColor: isHeaderTransparents
                    ? "transparent"
                    : colors.orange650,
                  color: "white",
                  borderRadius: "5px",
                  border: "1px solid white",
                }}
                onClick={() => {
                  customEvent({
                    newGtm: {
                      path: "gnb",
                      trigger_and_target: "contract_check",
                    },
                  });
                  router.push("/contract-check/");
                }}
              >
                계약서 확인
              </Button>
            )}
          </HStack>
          <HStack
            alignItems="center"
            justifyContent="center"
            gap={isBiggerThanLg ? "36px" : "20px"}
          >
            {!isHeaderContentHiddenRoute && (
              <>
                <HeaderTabBar isHeaderTransparents={isHeaderTransparents} />
                <Button
                  className="subtitle_14_b lg_under_hidden"
                  css={{
                    width: "112px",
                    padding: "6px 12px",
                    backgroundColor: isHeaderTransparents
                      ? "transparent"
                      : colors.orange650,
                    color: "white",
                    borderRadius: "5px",
                    border: "1px solid white",
                  }}
                  onClick={() => {
                    customEvent({
                      newGtm: {
                        path: "gnb",
                        trigger_and_target: "contract_check",
                      },
                    });
                    router.push("/contract-check/");
                  }}
                >
                  계약서 확인
                </Button>
              </>
            )}
          </HStack>
        </Stack>
      </VStack>
      {isHeaderTransparentPath || (
        <Spacing
          gap={
            isBiggerThanLg
              ? HEADER_HEIGHT
              : isHeaderContentHiddenRoute
              ? 66
              : 102
          }
        />
      )}
    </>
  );
}

function HeaderTabBar({
  isHeaderTransparents,
}: {
  isHeaderTransparents: boolean;
}) {
  const { activePath } = useActivePathCheck();
  const { isBiggerThanLg } = useResponsive();

  const menu: Array<{
    text: string;
    to: string;
    eventDetails: DirectEventTriggerAndTargetType["gnb"][];
    onClick?: () => void;
  }> = [
    {
      text: "홈",
      to: "/",
      eventDetails: ["uv_home"],
    },
    {
      text: "자주 묻는 질문",
      to: "https://goifuneral-faq.oopy.io/prime-web",
      eventDetails: ["uv_faq"],
    },
    {
      text: "기업 고객",
      to: "https://partners.goifuneral.co.kr/",
      eventDetails: ["uv_b2b"],
    },
    {
      text: "고이 소개",
      to: "/introduce/",
      eventDetails: ["uv_intro"],
    },
  ];

  return (
    <HStack
      css={responsive({
        height: { base: "40px", lg: "100%" },
      })}
      width="100%"
      justifyContent="space-between"
      gap={isBiggerThanLg ? "50px" : "0"}
    >
      {menu.map((item, i) => {
        return (
          <Box position="relative" key={i}>
            <NextLink href={item.to}>
              <TabBar.Tab
                className={
                  isHeaderTransparents
                    ? "subtitle_18_sb white"
                    : "subtitle_18_sb gray500"
                }
                onClick={() => {
                  if (item.onClick) {
                    item.onClick();
                  }
                  item.eventDetails.map((trigger_and_target) => {
                    customEvent({
                      newGtm: {
                        path: "gnb",
                        trigger_and_target,
                      },
                    });
                  });
                }}
                isActive={activePath === item.text}
                activeFontStyle={{
                  color: isHeaderTransparents ? colors.white : colors.gray900,
                }}
                activeBarStyle={{
                  borderBottom: isBiggerThanLg
                    ? "none"
                    : isHeaderTransparents
                    ? "3px solid white"
                    : "3px solid black",
                }}
                css={responsive({
                  height: { base: "40px", lg: "fit-content" },
                })}
              >
                {item.text}
              </TabBar.Tab>
            </NextLink>
          </Box>
        );
      })}
    </HStack>
  );
}
