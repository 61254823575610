import { useRouter } from "next/router";
import { useMemo } from "react";

export function useActivePathCheck() {
  const router = useRouter();

  const activePath = useMemo(() => {
    const path = router.asPath;
    if (path.startsWith("/introduce/")) return "고이 소개";
    else if (path.startsWith("/b2b/")) return "기업 고객";
    // else if (path.startsWith("/content/")) return "콘텐츠";
    else if (path === "/") return "홈";
    return "";
  }, [router]);

  return { activePath };
}
