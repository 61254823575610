import { VStack } from "goi_common";
import { useRouter } from "next/router";
import React, { useEffect, useMemo } from "react";

import { Modal, ModalOverlay, useDisclosure } from "@chakra-ui/react";

import Header from "./Header";
import Menu from "./Menu";
import TossHeader from "./TossHeader";
import * as ChannelService from "@channel.io/channel-web-sdk-loader";
import { useOverlay } from "@toss/use-overlay";

ChannelService.loadScript();

interface LayoutProps {
  children: React.ReactNode;
  showConditional?: boolean;
}

export default function Layout({ children, showConditional }: LayoutProps) {
  const router = useRouter();
  const overlay = useOverlay();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const isRouteIncludeToss = router.route.includes("toss");

  useEffect(() => {
    if (
      router.route.includes("/verification") ||
      router.route.includes("content/feeds/care-center")
    ) {
      return;
    }

    ChannelService.boot({
      pluginKey: process.env.NEXT_PUBLIC_CHANNELTALK_PLUGIN_KEY as string,
    });

    ChannelService.onShowMessenger(() => {
      overlay.open(({ isOpen, close: onClose }) => {
        return (
          <Modal
            isOpen={isOpen}
            onClose={() => {
              onClose();
            }}
            closeOnOverlayClick={false}
            scrollBehavior="inside"
            isCentered={true}
            autoFocus={false}
            closeOnEsc={false}
            returnFocusOnClose={false}
          >
            <ModalOverlay width="100%" height="100%" />
          </Modal>
        );
      });
    });
    ChannelService.onHideMessenger(() => {
      overlay.close();
    });
    return () => {
      ChannelService.shutdown();
    };
  }, []);

  const isHeaderHidden = useMemo(() => {
    if (showConditional) {
      return false;
    }

    return (
      router.route === "/login" ||
      router.route.includes("contract-check/") ||
      router.route.includes("contract-confirm") ||
      router.route === "/register-pay-method" ||
      router.route === "/register-pay-method/failure" ||
      router.route.includes("/event") ||
      router.route.includes("closed") ||
      router.route === "/content/feeds/sangjo" ||
      router.route === "/content/feeds/ceo-youtube" ||
      router.route.includes("review/") ||
      router.route.includes("content/feeds/tip") ||
      router.route === "/content/feeds/youtube/luckyquiz" ||
      isRouteIncludeToss
    );
  }, [router, showConditional]);

  return (
    <>
      {!isHeaderHidden && <Header onOpen={onOpen} />}
      {isRouteIncludeToss &&
        !router.route.includes("registration/toss-payment") && (
          <TossHeader onOpen={onOpen} />
        )}

      <VStack>{children}</VStack>

      <Menu isOpen={isOpen} onClose={onClose} />
    </>
  );
}
