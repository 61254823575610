import {
  VStack,
  colors,
  kakaoPixel,
  responsive,
  dableCustomEvent,
  DableEventName,
  daangnPixelTrack,
} from "goi_common";
import HeroSection from "./components/HeroSection";
import HighlightWordSection from "./components/HighlightWordSection";
import AboutSection from "./components/AboutSection";
import TrustSection from "./components/TrustSection";
import ReviewSection from "./components/ReviewSection";
import PRSection from "./components/PRSection";
import Footer from "../Home/components/Footer";
import { useEffect } from "react";
import BenefitSection from "./components/BenefitSection";
import NumberOneSection from "./components/NumberOneSection";
import CollaborationSection from "./components/CollaborationSection";
import QualitySection from "./components/QualitySection";
import LandingFixedButton from "@/components/common/LandingFixedButton";
import TrustDirectorSection from "./components/TrustDirectorSection";

import Why100SangjoSection from "./components/Why100SangjoSection";
import WhyNowSangjoSection from "./components/WhyNowSangjoSection";
import useNoticeModal from "@/hooks/useNoticeModal";

export default function HomeContainer() {
  const { openModal } = useNoticeModal({ isToss: false });

  useEffect(() => {
    kakaoPixel()?.pageView();
    daangnPixelTrack("ViewPage");
    dableCustomEvent(DableEventName.PageView);
    openModal();
  }, []);

  return (
    <VStack css={{ color: colors.white, overflowX: "hidden" }}>
      <HeroSection />
      <BenefitSection />
      <Why100SangjoSection />
      <WhyNowSangjoSection />

      <VStack background="white">
        <VStack
          background={colors.warmGray900}
          zIndex={3}
          css={responsive({
            paddingTop: { base: "72px", md: "140px" },
          })}
        >
          <HighlightWordSection />
          <AboutSection />
        </VStack>
      </VStack>
      <TrustDirectorSection />
      <QualitySection />
      <ReviewSection />

      <TrustSection />
      <NumberOneSection />
      <CollaborationSection />
      <PRSection />
      <Footer />

      <LandingFixedButton
        color={colors.gray900}
        onClick={() => {
          dableCustomEvent(DableEventName.ViewContent);
        }}
      />
    </VStack>
  );
}

// function Promotion9900FixedBox() {
//   const router = useRouter();

//   return (
//     <Box position="fixed" height="48px" top="110px" right="6px" zIndex={10}>
//       <Box
//         boxShadow="0px 0px 6px 0px rgba(0, 0, 0, 0.12)"
//         background={colors.white}
//         borderRadius="12px"
//         className="subtitle_14_b orange650"
//         css={responsive({
//           cursor: "pointer",
//           padding: { base: "12px 6px 12px 16px" },
//         })}
//         onClick={() => {
//           router.push("/main/9900/");
//           customEvent({
//             newGtm: {
//               path: "home",
//               trigger_and_target: "home_promotion-9900",
//             },
//           });
//         }}
//       >
//         <HStack width="100%" justifyContent="center" alignItems="center" gap={12}>
//           <Box>
//             <span className="body_14_m gray600">
//               <b>[프로모션]</b>월 9900원 납입 선택시
//             </span>
//             <br />총 59,400원 즉시 할인 프로모션
//           </Box>
//           <Box height="100%">
//             <ColorIcon name="arrow_circle_right" size={32} color={colors.gray600} />
//           </Box>
//         </HStack>
//       </Box>
//     </Box>
//   );
// }
