import { MAX_WIDTH } from "@/constants/components";
import { Box, Stack } from "@chakra-ui/react";
import { colors, getCdnImageSrc, Image, responsive, VStack } from "goi_common";

export default function Why100SangjoSection() {
  return (
    <VStack width="100%" alignItems="center">
      <VStack
        maxWidth={`${MAX_WIDTH}px`}
        width="100%"
        css={responsive({
          padding: { base: "0 24px", lg: "60px 24px" },
        })}
      >
        <Box className="gray900 head_24_b lg_head_42_b" textAlign="center">
          왜 월 100원인가요?
          <br />
          정말 가능한가요?
        </Box>
        <Stack
          gap="14px"
          alignItems="flex-end"
          css={responsive({
            flexDirection: { base: "column", lg: "row" },
            padding: { base: "40px 0 60px", lg: "120px 0 60px" },
          })}
        >
          <WhySangjoItem
            num={1}
            title={"가격 거품과\n소비자 피해를 막습니다."}
            description="고객의 믿고 맡긴 돈을 안전하게 보관합니다. 아름답고 깊은 애도를 위한 장례 준비는 월 100원이면 충분합니다."
          />
          <WhySangjoItem
            num={2}
            title={"IT 혁신을 통해\n원가와 비용을 줄였습니다."}
            description="데이터와 IT 기술을 활용하여 비용을 절감한 덕분에, 장례 발생 시 타사 대비 절반 가격으로도 최소한의 운영 이익을 확보했습니다."
          />
          <WhySangjoItem
            num={3}
            title={"탄탄한 자본으로\n더욱 믿을 수 있습니다"}
            description="카카오벤처스 등 업계 최초 벤처 투자 유치, 중소벤처기업부 및 신용보증기금 지원과 함께 안정적으로 회사를 운영하고 있습니다."
          />
        </Stack>
      </VStack>
    </VStack>
  );
}

function WhySangjoItem({
  num,
  title,
  description,
}: {
  num: number;
  title: string;
  description: string;
}) {
  return (
    <VStack
      padding="28px 20px 38px"
      background={colors.warmGray100}
      gap="26px"
      css={responsive({
        width: { base: "100%", lg: "33%" },
      })}
    >
      <Box>
        <Image
          src={getCdnImageSrc(`direct/home/why100-2-${num}.png`)}
          alt=""
          css={responsive({
            width: { base: "50px", lg: "64px" },
          })}
        />
      </Box>
      <Box whiteSpace="pre-line" className="gray900 subtitle_21_b lg_head_28_b">
        {title}
      </Box>
      <Box wordBreak="keep-all" className="gray600 subtitle_18_sb lg_body_16_r">
        {description}
      </Box>
    </VStack>
  );
}
